<template>
  <div class="home">
    <!-- <vue-swing
      class="main"
      :config="config"
      ref="swing"
      @throwout="throwout"
    >

      <img v-for="card in sorted" :src="require(`@/assets/cards/${card.number}.svg`)" />
    </vue-swing> -->
    <div class="main">
      <img v-if="!start" @click="start = true" :src="require(`@/assets/cards/0.svg`)" />
      <img :class="!start ? 'hidden' : ''" @click="throwout" v-for="card in sorted" :src="require(`@/assets/cards/${card.cardNumber}.svg`)" />
      <div v-if="start" class="card-number">{{ currentCard.number }}</div>
    </div>
    <div class="button" @click="startDeck">Start Over</div>
    <!-- <img class="hidden" :src="require(`@/assets/cards/0.svg`)" /> -->
    <!-- <h1 v-if="!lastCard">{{ remaining > 1 ? `${remaining - 1} Left` : 'Last Card' }}</h1>
    <h1 v-if="!lastCard">{{ sorted.length < 51 ? `${prevTotal} + ${currentCard.value} = ${runningTotal}` : prevCard ? `${prevCard.value} + ${currentCard.value} = ${runningTotal}` : currentCard.value }}</h1> -->
    <div v-if="blankCard" class="all-answers">
      <div v-for="answer in answers" @click="checkAnswer(answer)" class="answer-card">{{ answer }}</div>
    </div>
    <h1 v-if="result">{{ result }}</h1>
  </div>
</template>

<script>
import { allCards } from "@/ordered";
export default {
  name: 'Home',
  data() {
    return {
      answers: [1,2,3,4,5,6,7,8,9,10],
      start: false,
      blankCard: false,
      prevCard: "",
      lastCard: false,
      first: true,
      result: "",
      total: 0,
      zero: {
        "cardName": "blank",
        "cardNumber": 0,
        "suit": "blank",
        "number": 0,
        "value": 0
      },
      sorted: [],
      allCards,
      config: {
        throwOutConfidence(val) {
          if (val >= 150) return 1
        }
      }
    }
  },
  created() {
    this.startDeck()
    // this.cards = [...Array(50).keys()].sort((a,b) => a < b ? 1 : a > b ? -1 : 0)
  },
  computed: {
    currentCard() {
      this.total += this.sorted[this.sorted.length - 1].value
      return this.sorted[this.sorted.length - 1]
    },
    prevTotal() {
      if (this.sorted.length < 51) {
        let val = (this.total - this.currentCard.value) + this.currentCard.value
        return val < 10 ? val : val % 10
      }
      else return 0
    },
    runningTotal() {
      return this.total < 10 ? this.total : this.total % 10
    },
    remaining() {
      return this.sorted.length
    },
    // add() {
    //   if (!this.prevCard) return 
    //   else {
    //     let val = this.currentCard.value + this.runningTotal
    //     return val < 10 ? val : val % 10
    //   }
    // }
  },
  methods: {
    checkAnswer(val) {
      if (val === 10) val = 0
      if (val === this.sorted[0].value) this.result = "Correct!"
      else this.result = "Wrong!"
      this.blankCard = false
      this.throwout()
    },
    startDeck() {
      this.prevCard = ""
      this.sorted = JSON.parse(JSON.stringify(this.allCards.sort((a,b) => a.number < b.number ? 1 : a.number > b.number ? -1 : 0)))
    //   this.sorted.splice(1, 0, this.zero)
      // this.sorted.splice(this.sorted.length - 1, 0, this.zero)
      // this.total = this.sorted[this.sorted.length - 1].value
    },
    throwout(val) {
      if (this.blankCard) return
      // this.$refs.swing.cards[this.sorted.length - 1].throwOut()
      this.prevCard = this.sorted.pop()
    //   if (this.sorted.length === 2) this.blankCard = true
    //   else if (this.sorted.length === 1) {
    //     this.lastCard = true
    //   }
      if (!this.sorted.length) {
        this.result = ""
        this.start = false
        this.startDeck()
      } 
    }
  }
}
</script>

<style lang="scss" scoped>
.all-answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  .answer-card {
    width: 10%;
    margin: 1rem;
    border: 1px solid;
    padding: 1rem 0;
  }
}
.main {
  display: grid;
  width: 75%;
  margin: 2rem auto 0;
  position: relative;
  .card-number {
    position: absolute;
    left: 6%;
    bottom: 3%;
  }
  img {
    grid-area: 1 / 1;
    touch-action: manipulation;
  }
}
h1 {
  margin: 0;
}
.hidden {
  display: none;
}
.button {
    display: inline-block;
    padding: .5rem 1rem;
    background: #e44144;
    border: 1px solid;
    border-radius: 2px;
    margin-top: 2rem;
    color: white;
    font-size: 1.25rem;
}
</style>
