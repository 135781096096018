export const allCards = [
    {
        "cardName": "ace",
        "cardNumber": 1,
        "suit": "spades",
        "number": 17,
        "value": 1
    },
    {
        "cardName": "two",
        "cardNumber": 2,
        "suit": "spades",
        "number": 38,
        "value": 2
    },
    {
        "cardName": "three",
        "cardNumber": 3,
        "suit": "spades",
        "number": 13,
        "value": 3
    },
    {
        "cardName": "four",
        "cardNumber": 4,
        "suit": "spades",
        "number": 44,
        "value": 4
    },
    {
        "cardName": "five",
        "cardNumber": 5,
        "suit": "spades",
        "number": 42,
        "value": 5
    },
    {
        "cardName": "six",
        "cardNumber": 6,
        "suit": "spades",
        "number": 31,
        "value": 6
    },
    {
        "cardName": "seven",
        "cardNumber": 7,
        "suit": "spades",
        "number": 25,
        "value": 7
    },
    {
        "cardName": "eight",
        "cardNumber": 8,
        "suit": "spades",
        "number": 8,
        "value": 8
    },
    {
        "cardName": "nine",
        "cardNumber": 9,
        "suit": "spades",
        "number": 6,
        "value": 9
    },
    {
        "cardName": "ten",
        "cardNumber": 10,
        "suit": "spades",
        "number": 45,
        "value": 0
    },
    {
        "cardName": "jack",
        "cardNumber": 11,
        "suit": "spades",
        "number": 24,
        "value": 0
    },
    {
        "cardName": "queen",
        "cardNumber": 12,
        "suit": "spades",
        "number": 32,
        "value": 0
    },
    {
        "cardName": "king",
        "cardNumber": 13,
        "suit": "spades",
        "number": 21,
        "value": 0
    },
    {
        "cardName": "ace",
        "cardNumber": 14,
        "suit": "hearts",
        "number": 39,
        "value": 1
    },
    {
        "cardName": "two",
        "cardNumber": 15,
        "suit": "hearts",
        "number": 22,
        "value": 2
    },
    {
        "cardName": "three",
        "cardNumber": 16,
        "suit": "hearts",
        "number": 43,
        "value": 3
    },
    {
        "cardName": "four",
        "cardNumber": 17,
        "suit": "hearts",
        "number": 51,
        "value": 4
    },
    {
        "cardName": "five",
        "cardNumber": 18,
        "suit": "hearts",
        "number": 35,
        "value": 5
    },
    {
        "cardName": "six",
        "cardNumber": 19,
        "suit": "hearts",
        "number": 46,
        "value": 6
    },
    {
        "cardName": "seven",
        "cardNumber": 20,
        "suit": "hearts",
        "number": 7,
        "value": 7
    },
    {
        "cardName": "eight",
        "cardNumber": 21,
        "suit": "hearts",
        "number": 28,
        "value": 8
    },
    {
        "cardName": "nine",
        "cardNumber": 22,
        "suit": "hearts",
        "number": 5,
        "value": 9
    },
    {
        "cardName": "ten",
        "cardNumber": 23,
        "suit": "hearts",
        "number": 14,
        "value": 0
    },
    {
        "cardName": "jack",
        "cardNumber": 24,
        "suit": "hearts",
        "number": 16,
        "value": 0
    },
    {
        "cardName": "queen",
        "cardNumber": 25,
        "suit": "hearts",
        "number": 26,
        "value": 0
    },
    {
        "cardName": "king",
        "cardNumber": 26,
        "suit": "hearts",
        "number": 30,
        "value": 0
    },
    {
        "cardName": "ace",
        "cardNumber": 27,
        "suit": "diamonds",
        "number": 34,
        "value": 1
    },
    {
        "cardName": "two",
        "cardNumber": 28,
        "suit": "diamonds",
        "number": 18,
        "value": 2
    },
    {
        "cardName": "three",
        "cardNumber": 29,
        "suit": "diamonds",
        "number": 10,
        "value": 3
    },
    {
        "cardName": "four",
        "cardNumber": 30,
        "suit": "diamonds",
        "number": 11,
        "value": 4
    },
    {
        "cardName": "five",
        "cardNumber": 31,
        "suit": "diamonds",
        "number": 50,
        "value": 5
    },
    {
        "cardName": "six",
        "cardNumber": 32,
        "suit": "diamonds",
        "number": 52,
        "value": 6
    },
    {
        "cardName": "seven",
        "cardNumber": 33,
        "suit": "diamonds",
        "number": 27,
        "value": 7
    },
    {
        "cardName": "eight",
        "cardNumber": 34,
        "suit": "diamonds",
        "number": 48,
        "value": 8
    },
    {
        "cardName": "nine",
        "cardNumber": 35,
        "suit": "diamonds",
        "number": 23,
        "value": 9
    },
    {
        "cardName": "ten",
        "cardNumber": 36,
        "suit": "diamonds",
        "number": 40,
        "value": 0
    },
    {
        "cardName": "jack",
        "cardNumber": 37,
        "suit": "diamonds",
        "number": 1,
        "value": 0
    },
    {
        "cardName": "queen",
        "cardNumber": 38,
        "suit": "diamonds",
        "number": 4,
        "value": 0
    },
    {
        "cardName": "king",
        "cardNumber": 39,
        "suit": "diamonds",
        "number": 20,
        "value": 0
    },
    {
        "cardName": "ace",
        "cardNumber": 40,
        "suit": "clubs",
        "number": 12,
        "value": 1
    },
    {
        "cardName": "two",
        "cardNumber": 41,
        "suit": "clubs",
        "number": 2,
        "value": 2
    },
    {
        "cardName": "three",
        "cardNumber": 42,
        "suit": "clubs",
        "number": 3,
        "value": 3
    },
    {
        "cardName": "four",
        "cardNumber": 43,
        "suit": "clubs",
        "number": 19,
        "value": 4
    },
    {
        "cardName": "five",
        "cardNumber": 44,
        "suit": "clubs",
        "number": 41,
        "value": 5
    },
    {
        "cardName": "six",
        "cardNumber": 45,
        "suit": "clubs",
        "number": 47,
        "value": 6
    },
    {
        "cardName": "seven",
        "cardNumber": 46,
        "suit": "clubs",
        "number": 37,
        "value": 7
    },
    {
        "cardName": "eight",
        "cardNumber": 47,
        "suit": "clubs",
        "number": 33,
        "value": 8
    },
    {
        "cardName": "nine",
        "cardNumber": 48,
        "suit": "clubs",
        "number": 36,
        "value": 9
    },
    {
        "cardName": "ten",
        "cardNumber": 49,
        "suit": "clubs",
        "number": 15,
        "value": 0
    },
    {
        "cardName": "jack",
        "cardNumber": 50,
        "suit": "clubs",
        "number": 29,
        "value": 0
    },
    {
        "cardName": "queen",
        "cardNumber": 51,
        "suit": "clubs",
        "number": 9,
        "value": 0
    },
    {
        "cardName": "king",
        "cardNumber": 52,
        "suit": "clubs",
        "number": 49,
        "value": 0
    }
  ]